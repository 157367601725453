@import "@/assets/style/_index.scss";










































































































































































































































































































































































































.bar-code {
    padding: $padding;
    line-height: 0;
    background-color: #fff;
    > svg {
        width: 100%;
        height: auto;
    }
}
